<template>
	<AppLayout>
		<template #header>
			<PageHeader :center-title="false" :show-breadcrumbs="false" title="Template Builder">
				<template #actions>
					<Button color="white" :border="false" @click="createNewTemplate = true">
						Create new template
						<FontAwesomeIcon :icon="faPlus" class="h-5 w-5 text-black ml-2" aria-hidden="true" />
					</Button>
				</template>
			</PageHeader>
		</template>
		<template #primary>
			<div class="relative z-0 flex w-full h-full overflow-y-auto">
				<div class="lg:order-first lg:flex lg:flex-col lg:flex-grow shrink-0 h-full w-full">
					<div class="px-4 sm:px-6 lg:px-8 flex justify-center h-full w-full mb-1">
						<div v-if="!builderStore.loading" class="flex w-full h-full">
							<div class="w-full">
								<div class="flex-1 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:mx-0 w-full md:rounded-lg">
									<table class="flex-1 min-w-full divide-y divide-gray-300">
										<thead class="bg-gray-50">
											<tr>
												<th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
												<th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Description</th>
												<th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Type</th>
												<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
												<th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Updated by</th>
												<th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Created by</th>
											</tr>
										</thead>
										<tbody v-if="builderStore.templates.length > 0" class="divide-y divide-gray-200 bg-white">
											<tr
												v-for="(template, index) in builderStore.templates"
												:key="template.id"
												class="cursor-pointer hover:bg-gray-100"
												:class="index % 2 === 0 ? 'border-gray-300' : 'border-gray-200'"
												:title="
													router.resolve({
														name: 'builder.settings',
														params: {
															template_id: template.id,
														},
													}).fullPath
												"
												@click="
													router.push({
														name: 'builder.settings',
														params: {
															template_id: template.id,
														},
													})
												"
											>
												<td class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
													{{ template.name }}
													<dl class="font-normal lg:hidden">
														<dt class="sr-only">Description</dt>
														<dd class="mt-1 truncate text-gray-700">{{ template.description }}</dd>
														<dt class="sr-only sm:hidden">Type</dt>
														<dd class="mt-1 truncate text-gray-500 sm:hidden">{{ TemplateTypeLabel[template.type] }}</dd>
														<dt class="sr-only sm:hidden">Updated by</dt>
														<dd class="mt-1 truncate text-gray-500 sm:hidden">{{ template.updater.first_name }} {{ template.updater.last_name }}</dd>
														<dt class="sr-only sm:hidden">Created by</dt>
														<dd class="mt-1 truncate text-gray-500 sm:hidden">{{ template.creator.first_name }} {{ template.creator.last_name }}</dd>
													</dl>
												</td>
												<td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{{ template.description }}</td>
												<td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ TemplateTypeLabel[template.type] }}</td>
												<td class="px-3 py-4 text-sm text-gray-500">{{ TemplateStatusLabel[template.status] }}</td>
												<td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ template.updater.first_name }} {{ template.updater.last_name }}</td>
												<td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ template.creator.first_name }} {{ template.creator.last_name }}</td>
											</tr>
										</tbody>
										<tbody v-else class="divide-y divide-gray-200 bg-white">
											<tr>
												<td colspan="6">
													<div class="flex flex-row justify-center items-center my-6 font-semibold"><h1>You haven't created any templates yet!</h1></div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div v-else class="flex flex-row justify-center items-center h-full"><Loading /></div>
					</div>
				</div>
			</div>
			<CreateTemplate :open="createNewTemplate" @close="createNewTemplate = false" />
		</template>
	</AppLayout>
</template>

<script lang="ts" setup>
	import AppLayout from '@layouts/AppLayout.vue';
	import PageHeader from '@components/PageHeader.vue';
	import { useRouter } from 'vue-router';
	import { onMounted, ref } from 'vue';
	import Loading from '@components/Loading.vue';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faPlus } from '@fortawesome/pro-light-svg-icons';
	import { useBuilderStore } from '@modules/builder/store';
	import CreateTemplate from '@modules/builder/components/CreateTemplate.vue';
	import { TemplateTypeLabel, TemplateStatusLabel } from '@/types/activityTemplate';
	import Button from '@components/Button.vue';

	const router = useRouter();

	const createNewTemplate = ref(false);
	const builderStore = useBuilderStore();

	onMounted(() => {
		builderStore.getTemplates();
	});
</script>
